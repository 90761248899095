import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisFile from "@/components/shared/file"
import ThemisLogs from "@/components/shared/logs"
import ThemisInput from "@/components/shared/input"
import ThemisReadOnly from "@/components/shared/read-only"
import ThemisIssueDocuments from "@/components/issue/documents"
import ThemisScreen from "@/components/screen"
import IssueFields from "@/components/issue/fields"
import ThemisReportMessages from "@/components/report/messages"
import moment from "moment"
import { compareArray, convertDaysToDuration, getDueDateColor } from "@/utils"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import {
  FIELD_TYPES,
  ISSUE_TYPES,
  STATUS_CATEGORY_CLASS,
  DEFAULT_DATA_RETENTION_PERIODS,
  SCREEN_ITEM_DISPLAY_PROPERTIES,
  ISSUE_STATUS_CATEGORY,
  DISALLOWED_SCREEN_ITEMS_FOR_CATEGORY_SUB,
  TABLE_NAMES,
  AI_PREFILL_FIELDS_STATUS,
  AI_PREFILL_FIELDS_POLL_INTERVAL,
  NO_DATA_FOUND,
  NONE,
  MAX_CHARACTER_LIMIT,
  NO_MESSAGE_FOUND,
  TYPE,
  FEEDBACK_QUESTIONS,
  FEEDBACK_ANSWERS
} from "@/constants"
import { PRE_CONDITION_ERROR } from "@/constants/locales"

export default {
  name      : "IssueSidePanel",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisDecision,
    ThemisFile,
    ThemisLogs,
    ThemisDateTimePicker,
    ThemisIssueDocuments,
    ThemisInput,
    ThemisReadOnly,
    ThemisReportMessages,
    IssueFields,
    ThemisScreen
  },
  data: () => ({
    localIssue                        : undefined,
    canViewDomain                     : false,
    canUpdateDomain                   : false,
    canViewAssignee                   : false,
    canUpdateAssignee                 : false,
    canViewReceivedOn                 : false,
    canUpdateReceivedOn               : false,
    canViewAcknowledgedOn             : false,
    canUpdateAcknowledgedOn           : false,
    canUpdateDueDate                  : false,
    canViewDueDate                    : false,
    canViewStatus                     : false,
    canViewResolution                 : false,
    canUpdateStatus                   : false,
    canViewLabels                     : false,
    canUpdateLabels                   : false,
    canViewClosedOn                   : false,
    canViewCreatedOn                  : false,
    canViewUpdatedOn                  : false,
    canViewDataRetainedUntil          : false,
    canViewIssueFields                : false,
    isAssigneeInputFocussed           : false,
    isDomainInputFocussed             : false,
    isLabelsInputFocussed             : false,
    isReceivedOnInputFocussed         : false,
    isAcknowledgedOnInputFocussed     : false,
    isDueDateInputFocussed            : false,
    isDueDateUpdatedOnSave            : false,
    newLabel                          : undefined,
    isChangeDomainDialogOpen          : false,
    isReceivedOnDateTimePickerOpen    : false,
    isAcknowledgedOnDateTimePickerOpen: false,
    isDueDateDateTimePickerOpen       : false,
    isIssueFieldsExpanded             : true,
    selectedDataRetentionPeriod       : null,
    isScreenDialogOpen                : false,
    screenItemsForComponent           : [],
    dynamicScreenTitle                : undefined,
    localCurrentTransition            : undefined,
    aiPrefillFieldsStatusPolling      : null,
    showAiPrefillDialog               : false,
    aiPrefillFieldsSaved              : false,
    noAiSuggestedValueToPrefill       : false,
    localSelectedAiSuggestions        : [],
    aiPrefillFieldsTableData          : [],
    citationSelected                  : undefined,
    likeAiPrefillField                : false,
    currentExpandedExpansionPanels    : [0],
    noNewAiSuggestionsFound           : false,
    issueFieldsValuesLoaded           : false,
    showAiPrefillFieldFeedbackDialog  : false,
    feedbackOption                    : null,
    feedbackDetailText                : "",
    isUpdatingDomainThroughTransition : false
  }),

  beforeDestroy() {
    clearInterval(this.aiPrefillFieldsStatusPolling)
  },

  computed: {
    ...mapGetters({
      loggedInUser                                  : "auth/loggedInUser",
      accesses                                      : "accesses/accesses",
      loggedInUserPolicies                          : "accessControl/loggedInUserPolicies",
      languages                                     : "languages/languages",
      usersIncludingDeletedUsers                    : "users/usersIncludingDeletedUsers",
      groups                                        : "groups/groups",
      groupsUsers                                   : "groups/groupsUsers",
      domains                                       : "domains/domains",
      configurations                                : "configurations/configurations",
      issues                                        : "issues/issues",
      messages                                      : "messages/messages",
      messageItems                                  : "messageItems/messageItems",
      translations                                  : "translations/translations",
      isUpdatingAssignee                            : "issues/isUpdatingAssignee",
      isAssigneeUpdated                             : "issues/isAssigneeUpdated",
      isUpdatingDomain                              : "issues/isUpdatingDomain",
      isDomainUpdated                               : "issues/isDomainUpdated",
      isUpdatingStatus                              : "issues/isUpdatingStatus",
      isStatusUpdated                               : "issues/isStatusUpdated",
      resolutions                                   : "issueResolutions/issueResolutions",
      statuses                                      : "issueStatuses/issueStatuses",
      labels                                        : "labels/labels",
      isUpdatingLabels                              : "issues/isUpdatingLabels",
      isLabelsUpdated                               : "issues/isLabelsUpdated",
      isReceivedOnUpdated                           : "issues/isReceivedAtUpdated",
      isUpdatingReceivedOn                          : "issues/isUpdatingReceivedAt",
      isAcknowledgedOnUpdated                       : "issues/isAcknowledgedAtUpdated",
      isUpdatingAcknowledgedOn                      : "issues/isUpdatingAcknowledgedAt",
      isUpdatingDueDate                             : "issues/isUpdatingDueDate",
      issueViewPolicies                             : "accessControl/issueViewPolicies",
      issueUpdatePolicies                           : "accessControl/issueUpdatePolicies",
      reports                                       : "reports/reports",
      issueFields                                   : "issueFields/issueFields",
      fieldsV2                                      : "fields/fieldsV2",
      optionListItems                               : "optionListItems/optionListItems",
      issueFieldValues                              : "issueFieldValues/issueFieldValues",
      isUpdatingIssueFieldValue                     : "issueFieldValues/isUpdatingIssueFieldValue",
      isIssueFieldValueUpdated                      : "issueFieldValues/isIssueFieldValueUpdated",
      issueUpdateIssueFieldValuesPolices            : "accessControl/issueUpdateIssueFieldValuesPolices",
      issueTypes                                    : "issueTypes/issueTypes",
      dataRetentionPeriods                          : "dataRetentionPeriods/dataRetentionPeriods",
      workflows                                     : "workflows/workflows",
      isTransitionDone                              : "transitions/isTransitionDone",
      isTransitionInProgress                        : "transitions/isTransitionInProgress",
      isIssueFieldsEnabled                          : "configurations/isIssueFieldsEnabled",
      channels                                      : "channels/channels",
      workflowAssociations                          : "workflowAssociations/workflowAssociations",
      screenItems                                   : "screens/screenItems",
      aiPrefillField                                : "issues/aiPrefillField",
      isAiPrefillFieldsEnabled                      : "configurations/isAiPrefillFieldsEnabled",
      aiPrefillFieldFeedback                        : "feedback/aiPrefillFieldFeedback",
      isSubmittingAiPrefillFieldFeedback            : "feedback/isSubmittingAiPrefillFieldFeedback",
      isAiPrefillFieldFeedbackSubmitted             : "feedback/isAiPrefillFieldFeedbackSubmitted",
      isUpdatingAiPrefillFieldFeedback              : "feedback/isUpdatingAiPrefillFieldFeedback",
      isAiPrefillFieldFeedbackUpdated               : "feedback/isAiPrefillFieldFeedbackUpdated",
      allowedIssuesAndDomainsForIssueFieldViewPolicy: "accessControl/allowedIssuesAndDomainsForIssueFieldViewPolicy",
      allowedIssuesAndDomainsForIssueViewPolicy     : "accessControl/allowedIssuesAndDomainsForIssueViewPolicy",
      isAiSuggestionsEnabled                        : "configurations/isAiSuggestionsEnabled",
      isProgressBarInitiated                        : "shared/isProgressBarInitiated",
      isProgressBarPromisesPending                  : "shared/isProgressBarPromisesPending"
    }),
    dataRetainedUntilValueForDisplaying() {
      return this.localIssue.dataRetainedUntil === null
        ? this.$t("691")
        : this.$moment(this.localIssue.dataRetainedUntil).format("D MMMM YYYY")
    },
    domainsMap() {
      const domainsMap = new Object()
      for (const domain of this.domains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    translationsMap() {
      const translationsMap = new Object()
      for (const translation of this.translations) {
        translationsMap[translation.messageItemId] = translation
      }
      return translationsMap
    },
    resolutionsMap() {
      const resolutionsMap = new Object()
      for (const resolution of this.resolutions) {
        resolutionsMap[resolution.id] = resolution
      }
      return resolutionsMap
    },
    statusesMap() {
      const statusesMap = new Object()
      for (const status of this.statuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    issue() {
      return this.issues?.find(issue => issue.id === +this.$route.params.id)
    },
    report() {
      let report = this.reports?.find(report => report.issueId === this.issue?.id)
      if (report) {
        const messages = this.messages?.filter(message =>
          message.reportId === report.id
        )
        report         = Object.assign({}, report, {
          messages
        })
        if (report?.messages?.length && this.messageItems) {
          const indexOfMessages = new Object()
          for (const [index, message] of report.messages.entries()) {
            indexOfMessages[message.id] = index
          }
          for (let messageItem of this.messageItems) {
            const indexOfMessage = indexOfMessages[messageItem.messageId]
            if (indexOfMessage !== undefined) {
              messageItem = Object.assign({}, messageItem, {
                language: this.languagesMap[messageItem.languageId]
              })
              if (report.messages[indexOfMessage].items) {
                report.messages[indexOfMessage].items.push(messageItem)
              } else {
                report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                  items: [messageItem]
                })
              }
            }
            if (messageItem.version === "original" && this.translationsMap[messageItem.id]) {
              report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                translation: this.translationsMap[messageItem.id]
              })
            }
          }
        }
      }
      return report
    },
    channel() {
      const channel = this.channels?.find(channel => channel.id === this.report.channelId)
      if (channel) {
        if (channel.overrideTranslationPreference) {
          channel.effectiveTranslationPreference = channel.translationPreferenceId
        } else if (this.globalTranslationPreference) {
          channel.effectiveTranslationPreference = +this.globalTranslationPreference.value
        }
      }
      return channel
    },
    activeDomains() {
      const activeDomains            = this.domains?.filter(domain => domain.archived === false)
      const isIssueAssignedToADomain = !!this.localIssue.domainId

      if (isIssueAssignedToADomain) {
        const domain = this.domainsMap[this.localIssue.domainId]
        activeDomains.push(domain)
      }
      return activeDomains
    },
    activeDomainsToDisplay(){
      return this.activeDomains.map(activeDomain => {
        return {
          value            : activeDomain.id,
          text             : activeDomain.name,
          hasAccessToDomain: this.userHasAccessToTheDomain(activeDomain.id)
        }
      })
    },
    usersWithUnassignedOption() {
      const usersWithDetails = []
      const isIssueAssigned  = !!this.localIssue.assigneeId
      if (isIssueAssigned) {
        const user                          = this.usersMap[this.localIssue.assigneeId]
        const assignedUserNoLongerHasAccess = !this.usersWithAllLevelAccess.includes(this.localIssue.assigneeId)
        const assignedUserIsDisabled        = user ? !user.enabled : false
        if (assignedUserNoLongerHasAccess || assignedUserIsDisabled) {
          usersWithDetails.push(user)
        }
      }
      for (const userWithAccess of this.usersWithAllLevelAccess) {
        const user = this.usersMap[userWithAccess]
        if (user?.enabled) {
          usersWithDetails.push(user)
        }
      }
      return [{
        id  : 0,
        name: this.$t("372")
      }, ...usersWithDetails]
    },
    status() {
      return this.statuses.find(status => status.id === this.localIssue.statusId)
    },
    resolution() {
      return this.resolutions.find(resolution => resolution.id === this.issue?.resolutionId)
    },
    isAssigneeChanged() {
      return this.issue?.assigneeId !== this.localIssue?.assigneeId
    },
    isDomainChanged() {
      return this.issue?.domainId !== this.localIssue?.domainId
    },
    isReceivedOnChanged() {
      return new Date(this.issue?.receivedAt).getTime() !== new Date(this.localIssue?.receivedAt).getTime()
    },
    isAcknowledgedOnChanged() {
      return new Date(this.issue?.acknowledgedAt).getTime() !== new Date(this.localIssue?.acknowledgedAt).getTime()
    },
    getStatusClass() {
      return STATUS_CATEGORY_CLASS[this.statusesMap[this.issue?.statusId].category]
    },
    isStatusNew() {
      return this.issue?.statusId === this.$CONSTANTS.ISSUE_STATUS.NEW
    },
    isLabelsChanged() {
      return !compareArray(this.issue?.labels, this.localIssue?.labels)
    },
    labelsForSelection() {
      const labelsForSelection = [{
        header: this.$t("604")
      }]
      labelsForSelection.push(...this.labels.map(label => label.name))
      return labelsForSelection
    },
    receivedAtForDisplaying() {
      if (this.localIssue.receivedAt) {
        return this.$moment(this.localIssue.receivedAt).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    acknowledgedAtForDisplaying() {
      if (this.localIssue.acknowledgedAt) {
        return this.$moment(this.localIssue.acknowledgedAt).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    dueDateForDisplaying() {
      if (this.localIssue.dueDate) {
        return this.$moment(this.localIssue.dueDate).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    parentIssue() {
      return this.issues.find(issue => issue.id === this.issue?.parentId)
    },
    usersWithAllLevelAccess() {
      let usersWithAllLevelAccess = []
      for (const access of this.accesses) {
        if (this.issue.parentId) {
          if (access.criteria?.id === this.parentIssue?.id && access.userId) {
            usersWithAllLevelAccess.push(access.userId)
          } else if (access.criteria?.id === this.parentIssue?.id && access.groupId) {
            if (this.groupsUsers[access.groupId]) {
              usersWithAllLevelAccess = [...usersWithAllLevelAccess,
                ... this.groupsUsers[access.groupId]]
            }
          }
        }
        if (access.criteria?.id === this.issue?.id && access.userId) {
          usersWithAllLevelAccess.push(access.userId)
        } else if (access.criteria?.id === this.issue?.id && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithAllLevelAccess = [...usersWithAllLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      usersWithAllLevelAccess = [...usersWithAllLevelAccess, ... this.usersWithDomainLevelAccess]
      return [...new Set(usersWithAllLevelAccess)]
    },
    usersWithDomainLevelAccess() {
      let usersWithDomainLevelAccess = []
      for (const access of this.accesses) {
        const issue = this.issue?.parentId ? this.parentIssue : this.issue
        if (issue) {
          if (access.criteria?.domainId === issue.domainId && access.userId) {
            usersWithDomainLevelAccess.push(access.userId)
          } else if (access.criteria?.domainId === issue.domainId && access.groupId) {
            if (this.groupsUsers[access.groupId]) {
              usersWithDomainLevelAccess = [...usersWithDomainLevelAccess,
                ... this.groupsUsers[access.groupId]]
            }
          }
        }
      }
      return [...new Set(usersWithDomainLevelAccess)]
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    issueFieldsList() {
      return [...this.issueFields].sort((firstIssueField, secondIssueField) =>
        firstIssueField.sortingOrder - secondIssueField.sortingOrder
      ).map(issueField => {
        const field = this.fieldsMap.get(issueField.fieldId)
        let fieldId, fieldType, fieldWidget, fieldLabel, optionListId
        if (field) {
          fieldId = field.id,
          fieldType = field.type,
          fieldWidget = field.widget,
          fieldLabel = field.label,
          optionListId = field.optionListId
        }
        return {
          fieldId,
          issueFieldId: issueField.id,
          issueTypeId : issueField.issueTypeId,
          fieldType,
          fieldWidget,
          fieldLabel,
          optionListId,
          issueId     : +this.$route.params.id
        }
      })
    },
    initialDataForAiPrefillTable() {
      return this.issueFieldsList.map(issueField => ({ label: issueField.fieldLabel }))
    },
    issueUpdatePolicy() {
      return this.issueUpdatePolicies.find(issueUpdatePolicy => issueUpdatePolicy.id === +this.$route.params.id)
    },
    issueViewPolicy() {
      return this.issueViewPolicies.find(issueViewPolicy => issueViewPolicy.id === +this.$route.params.id)
    },
    isACase() {
      return this.issue.typeId === ISSUE_TYPES[0].id
    },
    isATask() {
      return this.issue.typeId === ISSUE_TYPES[1].id
    },
    issueType() {
      return this.issueTypes.find(issueType => issueType.id === this.issue.typeId)
    },
    workflowAssociation() {
      return this.workflowAssociations.find(workflowAssociation =>
        workflowAssociation.issueTypeId === this.issue.typeId
      )
    },
    workflow() {
      return this.workflows.find(workflow => workflow.id === this.workflowAssociation.workflowId)
    },
    transitions() {
      return this.workflow?.transitions
    },
    issueRelation() {
      return {
        ...this.issue,
        report: this.report ?? null
      }
    },
    issueTypeStatuses() {
      return this.issueType.statuses
    },
    issueStatuses() {
      if (!Array.isArray(this.transitions)) {
        return []
      }

      const statusesToConsider = []
      const sortedTransitions  = [...this.transitions].sort((firstTransition, secondTransition) =>
        firstTransition.id - secondTransition.id
      )

      for (const transition of sortedTransitions) {
        if (!transition.initialTransition) {
          const transitionLinks = transition.transitionLinks.filter(transitionLink =>
            transitionLink.toStatusId !== this.issue.statusId &&
            (transitionLink.fromStatusId === this.issue.statusId || transitionLink.fromStatusId === null)
          )
          for (const transitionLink of transitionLinks) {
            statusesToConsider.push({
              transitionId  : transition.id,
              toStatusId    : transitionLink.toStatusId,
              transitionName: transition.name
            })
          }
        }
      }
      const issueStatuses = []
      for (const statusToConsider of statusesToConsider) {
        const statusToPush = this.statusesMap[statusToConsider.toStatusId]
        issueStatuses.push({
          id          : statusToPush.id,
          name        : this.getTransitionDisplayName(statusToPush.name, statusToConsider.transitionName),
          transitionId: statusToConsider.transitionId,
          toStatusId  : statusToConsider.toStatusId
        })
      }
      return issueStatuses
    },
    isAssigneeALoggedInUser() {
      return this.issue.assigneeId === this.loggedInUser.id
    },
    retentionPeriods() {
      if (this.dataRetentionPeriods.length) {
        return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondRetentionPeriod) =>
          firstDataRetentionPeriod.value - secondRetentionPeriod.value)
          .map(dataRetentionPeriod => dataRetentionPeriod.value)
      }
      return DEFAULT_DATA_RETENTION_PERIODS
    },
    resolutionsToDisplay() {
      return this.resolutions.map(resolution => {
        return {
          value: resolution.id,
          text : resolution.name
        }
      })
    },
    retentionPeriodsToDisplay() {
      const dataRetentionPeriods = []
      for (const dataRetentionOption of this.retentionPeriods) {
        const dataRetentionPeriod = convertDaysToDuration(dataRetentionOption)
        dataRetentionPeriods.push({
          value: this.$moment().add(dataRetentionOption, "days").format("YYYY-MM-DD"),
          text : this.$tc(dataRetentionPeriod.localeValue, dataRetentionPeriod.count,
            { count: dataRetentionPeriod.count })
        })
      }
      return dataRetentionPeriods
    },
    isCloseButtonDisabled() {
      if (this.selectedDataRetentionPeriod === null || this.localIssue.resolutionId === null) {
        return true
      }
    },
    headersForAiPrefillFieldsTable() {
      return getHeadersForTable(TABLE_NAMES.AI_PREFILL_FIELDS, this.$t.bind(this))
    },
    aiPrefillFieldOfCurrentIssue() {
      if (this.aiPrefillField.issueId === this.issue?.id) {
        return this.aiPrefillField
      }
      return null
    },
    aiPrefillFieldStatus() {
      return this.aiPrefillFieldOfCurrentIssue?.status
    },
    showAiPrefillFieldsButton() {
      const issueFieldValuesPolicy         = this.issueUpdateIssueFieldValuesPolices.find(issueFieldUpdatePolicy =>
        issueFieldUpdatePolicy.id === this.issue.id)
      const issueFieldValuePolicyHasSetAdd = issueFieldValuesPolicy?.set?.add !== undefined
      return this.isAiPrefillFieldsEnabled &&
        !!this.report &&
        issueFieldValuePolicyHasSetAdd &&
        this.isAiSuggestionsEnabled
    },
    isPrefillFieldsButtonDisabled() {
      return !this.localSelectedAiSuggestions.length
    },
    areAllIssueFieldValuesSet() {
      return this.issueFields.every(issueField => {
        const issueFieldValues = this.getIssueFieldValuesForIssueField(issueField.id)
        return issueFieldValues.length && issueFieldValues.every(issueFieldValue => issueFieldValue.value)
      })
    },
    checkIfAllIssueFieldValuesAreEmpty() {
      return this.issueFields.every(issueField => {
        const issueFieldValues = this.getIssueFieldValuesForIssueField(issueField.id)
        return !issueFieldValues.length || !issueFieldValues.some(issueFieldValue => issueFieldValue.value)
      })
    },
    issueFieldsMap() {
      const issueFieldsMap = new Map()
      for (const issueField of this.issueFields) {
        issueFieldsMap.set(issueField.id, issueField)
      }
      return issueFieldsMap
    },
    showInformationMessage() {
      if (this.aiPrefillFieldOfCurrentIssue && this.aiPrefillFieldOfCurrentIssue.result?.length) {
        if (this.isAiSuggestedValuesAndMessageItemsLoaded){
          const results = this.aiPrefillFieldOfCurrentIssue.result.map(aiPreFillField => {
            const issueField            = this.issueFieldsMap.get(aiPreFillField.issueFieldId)
            const field                 = this.fieldsMap.get(issueField.fieldId)
            const issueFieldValues      = this.getIssueFieldValuesForIssueField(issueField.id)
            const allIssueValuesPresent =  issueFieldValues.length &&
            issueFieldValues.every(issueFieldValue => issueFieldValue.value)

            switch (field.type) {
              case FIELD_TYPES.OPTION_LIST.value: {
                const currentValue = allIssueValuesPresent ? issueFieldValues[0].value : null
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              case FIELD_TYPES.DATE.value: {
                const currentValue = allIssueValuesPresent ? this.$moment(issueFieldValues[0].value).format("DD MMMM YYYY") : null
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              case FIELD_TYPES.DATE_TIME.value: {
                const currentValue = allIssueValuesPresent ? this.$moment(issueFieldValues[0].value).format("DD MMMM YYYY HH:mm") : null
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              case FIELD_TYPES.BOOLEAN.value:{
                const currentValue = allIssueValuesPresent
                  ? (issueFieldValues[0].value === "true"
                    ? "Yes"
                    : issueFieldValues[0].value === "false"
                      ? "No"
                      : "No")
                  : null
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              case FIELD_TYPES.MULTIPLE_OPTION_LIST.value: {
                const currentValue = allIssueValuesPresent && issueFieldValues.map(issueField => issueField.value).join(", ")
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              case FIELD_TYPES.NUMBER.value:{
                const currentValue = allIssueValuesPresent ? issueFieldValues[0].value : null
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              case FIELD_TYPES.SHORT_TEXT.value: {
                if (aiPreFillField.value.length > MAX_CHARACTER_LIMIT.ISSUE_FIELDS_SHORT_TEXT_FIELD) {
                  return true
                }
                const currentValue = allIssueValuesPresent ? issueFieldValues[0].value : null
                if (currentValue !== aiPreFillField.value) {
                  return aiPreFillField.value === NO_DATA_FOUND && currentValue !== null
                }
                return true
              }
              default:
                return true
            }
          })

          const allTrue = results.every(result => result === true)
          return (this.noNewAiSuggestionsFound && this.areAllIssueFieldValuesSet) || allTrue
        }
      }
    },
    isMessageItemsLoaded() {
      return this.report.messages?.every(message => message.items && message.items.length > 0)
    },
    isAiSuggestedValuesAndMessageItemsLoaded() {
      return this.issueFieldsValuesLoaded && this.isMessageItemsLoaded
    },
    getClassForDueDate() {
      const statusCategory    = this.statusesMap[this.issue.statusId].category
      const isNewOrInProgress = statusCategory === ISSUE_STATUS_CATEGORY.NEW
        || statusCategory === ISSUE_STATUS_CATEGORY.IN_PROGRESS
      return getDueDateColor(this.dueDateForDisplaying, isNewOrInProgress)
    },
    showSkeletonLoaderForStatus() {
      return this.canViewStatus && (!this.statuses || !this.localIssue)
    },
    showSkeletonLoaderForResolution() {
      return this.isACase && this.canViewResolution && (!this.resolutions || !this.localIssue)
    },
    showSkeletonLoaderForAssignee() {
      return this.canViewAssignee && (!this.usersIncludingDeletedUsers || !this.localIssue)
    },
    showSkeletonLoaderForDomain() {
      return this.isACase && this.canViewDomain && (!this.domains || !this.localIssue)
    },
    showSkeletonLoaderForLabels() {
      return this.isACase && this.canViewLabels && (!this.labels || !this.localIssue)
    },
    showSkeletonLoaderForIssueFields() {
      if (!(this.isACase && this.isIssueFieldsEnabled && this.canViewIssueFields)) {
        return false
      } else if(!this.issueFields.length || this.isAllPromisesResolved) {
        return false
      }
      return true
    },
    isAllPromisesResolved() {
      return !(this.isProgressBarInitiated && this.isProgressBarPromisesPending)
    }
  },
  methods: {
    ...mapActions({
      updateIssue                 : "issues/updateIssue",
      notify                      : "shared/notify",
      updateIssueFieldValue       : "issueFieldValues/updateIssueFieldValue",
      makeTransition              : "transitions/makeTransition",
      addAiPrefillField           : "issues/addAiPrefillField",
      loadAiPrefillField          : "issues/loadAiPrefillField",
      addAiPrefillFieldFeedback   : "feedback/addAiPrefillFieldFeedback",
      updateAiPrefillFieldFeedback: "feedback/updateAiPrefillFieldFeedback"
    }),
    ...mapMutations({
      resetAiPrefillField: "issues/resetAiPrefillField"
    }),

    getIssueFieldValuesForIssueField(issueFieldId) {
      return this.issueFieldValues
        .filter(issueFieldValue =>
          issueFieldValue.issueFieldId === issueFieldId &&
          issueFieldValue.issueId === +this.$route.params.id
        )
    },
    handleAiPrefillFieldSelection(aiSuggestion) {
      this.citationSelected = undefined
      if (aiSuggestion.citation !== NO_MESSAGE_FOUND) {
        this.citationSelected = aiSuggestion.citation
      }
    },
    selfAssignIssue() {
      this.localIssue.assigneeId = this.loggedInUser.id
      this.handleUpdateAssignee()
    },
    handleUpdateAssignee() {
      let assigneeId = this.localIssue.assigneeId
      if (this.isAssigneeChanged && assigneeId !== null) {
        if (assigneeId === 0) {
          assigneeId = null
        }
        this.updateIssue({
          id: this.localIssue.id,
          assigneeId
        })
      }
    },
    handleAssigneeInputOnBlurEvent(onBlur) {
      onBlur()
      if (this.localIssue.assigneeId === null) {
        if (this.issue.assigneeId === null) {
          this.localIssue.assigneeId = 0
        } else {
          this.localIssue.assigneeId = this.issue.assigneeId
        }
      }
    },
    getValueFromPath(obj, path) {
      return path.split(".").reduce((prev, curr) => {
        if (prev !== null) {
          return Object.prototype.hasOwnProperty.call(prev, curr) ? prev[curr] : null
        } else {
          return null
        }
      }, obj)
    },
    async updateStatus(status) {
      const transition                       = this.transitions.find(transition =>
        transition.id === status.transitionId
      )
      this.localCurrentTransition            = { ...transition }
      this.isUpdatingDomainThroughTransition = false
      const preConditions                    = transition.preConditions
      if (preConditions.length) {
        for (const preCondition of preConditions) {
          for (const [preConditionPath, preConditionValue] of Object.entries(preCondition.value)) {
            const isManuallyCreatedIssue  = this.issueRelation.report === null
            const hasReportPropertyInPath = preConditionPath.split(".").includes("report")
            const pathValue               = this.getValueFromPath(this.issueRelation, preConditionPath)
            if (preConditionValue !== pathValue && !(isManuallyCreatedIssue && hasReportPropertyInPath)) {
              if (PRE_CONDITION_ERROR[preConditionPath]) {
                this.notify({
                  type: "error",
                  text: PRE_CONDITION_ERROR[preConditionPath]
                })
                return
              }
            }
            if (preConditionPath === "groupId" && preConditionValue.length) {
              let userInGroup = false
              for (const groupId of preConditionValue) {
                const groupUsers = this.groupsUsers[groupId]
                if (groupUsers && groupUsers.includes(this.loggedInUser.id)) {
                  userInGroup = true
                  break
                }
              }
              if (!userInGroup) {
                this.notify({
                  type: "error",
                  text: this.$t("1904")
                })
                return
              }
            }
            if (preConditionPath === "roleId" && preConditionValue.length) {
              let userInRole = false
              for (const roleId of preConditionValue) {
                if (this.loggedInUser.roles.includes(roleId)) {
                  userInRole = true
                  break
                }
              }
              if (!userInRole) {
                this.notify({
                  type: "error",
                  text: this.$t("1904")
                })
                return
              }
            }
          }
        }
      }
      const screen = transition.screen
      if (screen) {
        this.screenItemsForComponent = screen.screenItems
          .filter(screenItem => {
            if (this.isATask) {
              return !DISALLOWED_SCREEN_ITEMS_FOR_CATEGORY_SUB.includes(screenItem.key)
            }
            return true
          })
          .sort((firstScreenItem, secondScreenItem) => firstScreenItem.sortingOrder - secondScreenItem.sortingOrder)
          .map(screenItem => {
            let issueFieldForScreen
            let issuePropertyValue
            if (screenItem && screenItem.fields.length) {
              const [screenItemField] = screenItem.fields
              const field             = this.fieldsMap.get(screenItemField.id)
              const issueFieldId      = this.issueFields.find(
                issueField => issueField.fieldId === screenItemField.id)?.id
              if (field) {
                issueFieldForScreen                  = {
                  fieldId             : field.id,
                  issueFieldId        : issueFieldId,
                  fieldType           : field.type,
                  fieldWidget         : field.widget,
                  fieldLabel          : screenItem.mandatory ? `${field.label}*` : field.label,
                  optionListId        : field.optionListId,
                  issueId             : +this.$route.params.id,
                  isOptionListCascaded: this.isOptionListCascaded(field)
                }
                issueFieldForScreen.issueFieldValues = this.getIssueFieldValuesForAParticularIssueField(
                  issueFieldForScreen
                )
              }
              issueFieldForScreen = {
                ...issueFieldForScreen,
                optionListItems: this.getOptionListItemsForAParticularIssueField(issueFieldForScreen),
                readOnly       : this.isReadOnlyIssueFields(issueFieldForScreen)
              }
            } else {
              if (screenItem.key === "assigneeId" && !this.localIssue[screenItem.key] ) {
                issuePropertyValue = null
              } else {
                issuePropertyValue = this.localIssue[screenItem.key]
              }
            }
            return {
              key      : screenItem.key,
              mandatory: screenItem.mandatory,
              items    : this[SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key]?.computedPropertyValue],
              field    : issueFieldForScreen,
              value    : issuePropertyValue
            }
          })
        this.isScreenDialogOpen = true
        this.dynamicScreenTitle = this.statusesMap[status.id].name
        return
      }
      this.makeTransition({
        id  : this.localCurrentTransition.id,
        data: {
          id      : this.issue.id,
          statusId: status.toStatusId
        }
      })
    },
    removeLabel(labelToBeRemoved) {
      this.localIssue.labels = this.localIssue.labels.filter(label => label !== labelToBeRemoved)
    },
    handleUpdateReceivedAt() {
      this.isReceivedOnDateTimePickerOpen = false
      this.updateIssue({
        id        : this.localIssue.id,
        receivedAt: this.localIssue.receivedAt
      })
    },
    handleLabelInputOnFocusEvent(onFocus) {
      onFocus()
      this.isLabelsInputFocussed = true
    },
    handleLabelInputOnBlurEvent(onBlur) {
      onBlur()
      this.isLabelsInputFocussed = false
    },
    handleUpdateAcknowledgedAt() {
      this.isAcknowledgedOnDateTimePickerOpen = false
      this.updateIssue({
        id            : this.localIssue.id,
        acknowledgedAt: this.localIssue.acknowledgedAt
      })
    },
    handleUpdateDomain() {
      this.updateIssue({
        id      : this.localIssue.id,
        domainId: this.localIssue.domainId
      })
    },
    handleResetReceivedAt() {
      this.localIssue.receivedAt = this.issue.receivedAt ? new Date(this.issue.receivedAt) : null
    },
    handleResetAcknowledgedAt() {
      this.localIssue.acknowledgedAt = this.issue.acknowledgedAt ? new Date(this.issue.acknowledgedAt) : null
    },
    handleResetDueDate() {
      this.localIssue.dueDate = this.issue.dueDate ? new Date(this.issue.dueDate) : null
    },
    handleCloseAcknowledgementAtDateTimePicker() {
      this.isAcknowledgedOnDateTimePickerOpen = false
      this.handleResetAcknowledgedAt()
    },
    handleCloseReceivedAtDateTimePicker() {
      this.isReceivedOnDateTimePickerOpen = false
      this.handleResetReceivedAt()
    },
    handleReceivedOnInputOnFocusEvent(onFocus) {
      onFocus()
      this.isReceivedOnInputFocussed = true
    },
    handleReceivedOnInputOnBlurEvent(onBlur) {
      onBlur()
      this.isReceivedOnInputFocussed = false
    },
    handleAcknowledgedInputOnFocusEvent(onFocus) {
      onFocus()
      this.isAcknowledgedOnInputFocussed = true
    },
    handleAcknowledgedInputOnBlurEvent(onBlur) {
      onBlur()
      this.isAcknowledgedOnInputFocussed = false
    },
    handleDueDateInputOnFocusEvent(onFocus) {
      onFocus()
      this.isDueDateInputFocussed = true
    },
    handleDueDateInputOnBlurEvent(onBlur) {
      onBlur()
      this.isDueDateInputFocussed = false
    },
    handleCloseDueDateDateTimePicker() {
      this.isDueDateDateTimePickerOpen = false
      this.handleResetDueDate()
    },
    handleUpdateDueDate() {
      this.isDueDateDateTimePickerOpen = false
      this.isDueDateUpdatedOnSave      = true
      this.updateIssue({
        id     : this.localIssue.id,
        dueDate: this.localIssue.dueDate
      })
      this.isDueDateInputFocussed = false
    },
    handleClearDueDate() {
      this.localIssue.dueDate     = null
      this.isDueDateUpdatedOnSave = false
      this.updateIssue({
        id     : this.localIssue.id,
        dueDate: this.localIssue.dueDate
      })
    },
    cancelUpdateDomain() {
      this.localIssue.domainId = this.issue.domainId
    },
    handleDateConversion(value) {
      return this.$moment(value).format("D MMMM YYYY HH:mm (UTCZ)")
    },
    toggleExpandCollapseIssueFields() {
      this.isIssueFieldsExpanded = !this.isIssueFieldsExpanded
    },
    getIssueFieldValuesForAParticularIssueField(issueField) {
      return this.issueFieldValues
        .filter(issueFieldValue =>
          issueFieldValue.issueFieldId === issueField.issueFieldId &&
          issueFieldValue.issueId === +this.$route.params.id
        )
    },
    getOptionListItemsForAParticularIssueField(issueField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value]
        .includes(issueField.fieldType)) {
        const sortedOptionListItems = this.optionListItems
          .filter(optionListItem => optionListItem.optionListId === issueField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id      : optionListItem.id,
            name    : optionListItem.name,
            parentId: optionListItem.parentId
          }))

        const isMultiple              = this.isFieldTypeMultipleOptionList(issueField)
        const options                 = this.generateNestedOptionListItems(sortedOptionListItems, isMultiple)
        const currentIssueFieldValues = this.getIssueFieldValuesForAParticularIssueField(issueField)

        for (const issueFieldValue of currentIssueFieldValues) {
          const isOptionListItemExists = sortedOptionListItems.find(option => issueFieldValue.value === option.name)
          if (!isOptionListItemExists && issueFieldValue.value) {
            options.push({ name: issueFieldValue.value })
          }
        }
        return options
      }
    },
    isFieldTypeMultipleOptionList(issueField) {
      return issueField.fieldType === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    generateNestedOptionListItems(data, isMultiple) {
      const idToObject = {}
      const result     = []

      data.forEach(item => {
        idToObject[item.id] = { ...item, children: [] }
      })

      data.forEach(item => {
        const parent = idToObject[item.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(idToObject[item.id])
        } else {
          result.push(idToObject[item.id])
        }
      })

      return result
    },
    getIssueUpdateIssueFieldValuesPolicesForAParticularIssueField(issueField) {
      return this.issueUpdateIssueFieldValuesPolices.find(issueFieldUpdatePolicy =>
        issueFieldUpdatePolicy.id === issueField.issueId)
    },
    isReadOnlyIssueFields(issueField) {
      const issueFieldValuesPolicy = this.getIssueUpdateIssueFieldValuesPolicesForAParticularIssueField(issueField)

      const issueFieldValuePolicyHasSetAdd = issueFieldValuesPolicy && issueFieldValuesPolicy?.set?.add !== undefined
      return !issueFieldValuePolicyHasSetAdd
    },
    isOptionListCascaded(issueField) {
      const filteredOptionListItems = this.optionListItems.filter(optionList =>
        optionList.optionListId === issueField.optionListId)
      return !!filteredOptionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    handleUpdateIssue(screenItemsValue) {
      this.isUpdatingDomainThroughTransition = !!Object.keys(screenItemsValue).find(key => key === "domainId")

      const transitionToStatusId = this.getTransitionToStatusId(this.localCurrentTransition.id)
      this.makeTransition({
        id  : this.localCurrentTransition.id,
        data: {
          ...screenItemsValue,
          id      : this.issue.id,
          statusId: transitionToStatusId
        }
      })
    },
    handleCancelUpdateIssue() {
      this.isScreenDialogOpen      = false
      this.localCurrentTransition  = undefined
      this.dynamicScreenTitle      = undefined
      this.screenItemsForComponent = []
    },
    getTransitionDisplayName(statusName, transitionName) {
      return this.$t("1422", {
        statusName,
        transitionName
      })
    },
    getTransitionToStatusId(transitionId) {
      const transition = this.transitions.find(transition => transition.id === transitionId)
      return transition.transitionLinks.find(transitionLink =>
        transitionLink.transitionId === transitionId).toStatusId
    },
    userHasAccessToTheDomain(domainId) {
      return this.allowedIssuesAndDomainsForIssueViewPolicy.domainIds.includes(domainId)
    },
    handleClickAiPrefillField() {
      this.showAiPrefillDialog      = true
      this.aiPrefillFieldsTableData = this.initialDataForAiPrefillTable
      this.addAiPrefillField({
        id: this.issue.id
      })
    },
    clearAiPrefillFieldsPollingInterval() {
      if (this.aiPrefillFieldsStatusPolling) {
        clearInterval(this.aiPrefillFieldsStatusPolling)
        this.aiPrefillFieldsStatusPolling = null
      }
    },
    handleCancel() {
      this.showAiPrefillDialog         = false
      this.noAiSuggestedValueToPrefill = false
      this.localSelectedAiSuggestions  = []
      this.aiPrefillFieldsTableData    = []
      this.issueFieldsValuesLoaded     = false
      clearInterval(this.aiPrefillFieldsStatusPolling)
      this.resetAiPrefillField()
    },
    formattedCurrentValue(issueField) {
      const issueFieldValues = this.getIssueFieldValuesForAParticularIssueField(issueField)
      let currentValue       = issueFieldValues[0]?.value
      const field            = this.fieldsMap.get(issueField.fieldId)

      switch (field.type) {
        case FIELD_TYPES.DATE.value:
          return currentValue
            ? this.$moment(currentValue).format("DD MMMM YYYY")
            : undefined
        case FIELD_TYPES.DATE_TIME.value:
          return currentValue
            ? this.$moment(currentValue).format("DD MMMM YYYY HH:mm")
            : undefined
        case FIELD_TYPES.BOOLEAN.value:
          return currentValue === "true" ? this.$t("1048")
            : currentValue === "false" ? this.$t("1049")
              : currentValue
        case FIELD_TYPES.MULTIPLE_OPTION_LIST.value:
          currentValue = issueFieldValues.map(value => value.value).join(", ")
          return currentValue
        default:
          return currentValue
      }
    },
    handleSaveAiPrefillFields() {
      const issueFieldValues = []

      for (const suggestion of this.localSelectedAiSuggestions) {
        const { fieldId, issueFieldId, aiSuggestedValue } = suggestion
        const result                                      = this.validateAISuggestedIssueFieldValue(
          fieldId,
          issueFieldId,
          aiSuggestedValue
        )
        if(result) {
          if (Array.isArray(result)) {
            for (const object of result) {
              issueFieldValues.push(object)
            }
          } else {
            issueFieldValues.push(result)
          }
        }
      }
      if (issueFieldValues.length) {
        this.updateIssueFieldValue(issueFieldValues)
        this.aiPrefillFieldsSaved = true
      }
      this.localSelectedAiSuggestions = []
      this.showAiPrefillDialog        = false
      this.issueFieldsValuesLoaded    = false
    },
    validateAISuggestedIssueFieldValue(fieldId, issueFieldId, aiSuggestedValue) {
      //this method validates the aiSuggested value for a issue field before saving them
      const field = this.fieldsMap.get(fieldId)
      let optionListId, fieldType
      if (field) {
        fieldType = field.type,
        optionListId = field.optionListId
      }
      if (optionListId) {
        if (fieldType === FIELD_TYPES.OPTION_LIST.value && aiSuggestedValue !== NONE) {
          return {
            issueFieldId,
            value  : aiSuggestedValue,
            issueId: this.issue.id
          }
        } else if (fieldType === FIELD_TYPES.MULTIPLE_OPTION_LIST.value && aiSuggestedValue !== NONE) {
          const multiOptionSelectValuesArray = []
          const trimmedAiSuggestedValues     = aiSuggestedValue.split(",").map(value => value.trim())
          trimmedAiSuggestedValues.forEach(value => {
            multiOptionSelectValuesArray.push({
              issueFieldId: issueFieldId,
              value,
              issueId     : this.issue.id
            })
          })
          if (multiOptionSelectValuesArray.length) {
            return multiOptionSelectValuesArray
          }
        }
      } else if (fieldType === FIELD_TYPES.BOOLEAN.value) {
        let booleanValue = null
        if (aiSuggestedValue === "Yes") {
          booleanValue = "true"
          return {
            issueFieldId,
            value  : booleanValue,
            issueId: this.issue.id
          }
        } else if (aiSuggestedValue === "No") {
          booleanValue = "false"
          return {
            issueFieldId,
            value  : booleanValue,
            issueId: this.issue.id
          }
        }

      }  else if (fieldType === FIELD_TYPES.DATE.value) {
        const isDateValid = moment(aiSuggestedValue, "DD MMMM YYYY", true).isValid()
        if (isDateValid) {
          const formattedDate = moment(aiSuggestedValue).format("YYYY-MM-DD")
          return {
            issueFieldId,
            value  : formattedDate,
            issueId: this.issue.id
          }
        }
      } else if (fieldType === FIELD_TYPES.DATE_TIME.value) {
        const isDateTimeValid = moment(aiSuggestedValue, "DD MMMM YYYY HH:mm", true)
        if (isDateTimeValid.isValid()) {
          const formattedDate = moment(aiSuggestedValue, "DD MMMM YYYY HH:mm").toISOString()
          return {
            issueFieldId,
            value  : formattedDate,
            issueId: this.issue.id
          }
        }
      }  else if (fieldType === FIELD_TYPES.SHORT_TEXT.value) {
        if (aiSuggestedValue !== NONE && aiSuggestedValue.length <= MAX_CHARACTER_LIMIT.ISSUE_FIELDS_SHORT_TEXT_FIELD) {
          return {
            issueFieldId,
            value  : aiSuggestedValue,
            issueId: this.issue.id
          }
        }
      } else if (fieldType === FIELD_TYPES.NUMBER.value) {
        if (!isNaN(Number(aiSuggestedValue))) {
          return {
            issueFieldId,
            value  : aiSuggestedValue,
            issueId: this.issue.id
          }
        }
      }
    },
    handleLikeAiPrefillField() {
      this.likeAiPrefillField = true
      this.addAiPrefillFieldFeedback({
        type    : TYPE.AI_PREFILL_FIELDS,
        feedback: [{
          "question": FEEDBACK_QUESTIONS.FIRST,
          "answer"  : FEEDBACK_ANSWERS.YES
        }]
      })
    },
    handleDislikeAiPrefillField() {
      this.addAiPrefillFieldFeedback({
        type    : TYPE.AI_PREFILL_FIELDS,
        feedback: [{
          "question": FEEDBACK_QUESTIONS.FIRST,
          "answer"  : FEEDBACK_ANSWERS.NO
        }]
      })
      this.showAiPrefillFieldFeedbackDialog = true
    },
    handleCancelAiPrefillFieldFeedback(){
      this.showAiPrefillFieldFeedbackDialog = false
      this.feedbackOption                   = null
      this.feedbackDetailText               = ""
    },
    handleSubmitAiPrefillFieldFeedback(){
      if (this.aiPrefillFieldFeedback.feedback.length === 1 &&
        this.aiPrefillFieldFeedback.feedback[0].question === FEEDBACK_QUESTIONS.FIRST) {
        this.updateAiPrefillFieldFeedback({
          uuid    : this.aiPrefillFieldFeedback.uuid,
          feedback: [
            ...this.aiPrefillFieldFeedback.feedback,
            {
              question: FEEDBACK_QUESTIONS.SECOND,
              answer  : this.feedbackOption
            },
            {
              question: FEEDBACK_QUESTIONS.THIRD,
              answer  : this.feedbackDetailText
            }
          ]
        })
      }
      this.showAiPrefillFieldFeedbackDialog = false
      this.feedbackOption                   = false
      this.feedbackDetailText               = ""
    },
    showBothCurrentAndAiSuggestedValue(item) {
      return item.currentValue &&
        item.aiSuggestedValue &&
        item.currentValue !== item.aiSuggestedValue &&
        item.aiSuggestedValue !== NONE
    },
    showSkeletonLoaderForAiSuggestedValue(item) {
      return !item.aiSuggestedValue
    },
    showSkeletonLoaderForLabel(item) {
      return !item.label
    },
    getDisplayValue(item) {
      if (item.aiSuggestedValue === NONE && item.currentValue) {
        return item.currentValue
      } else {
        return item.aiSuggestedValue
      }
    },
    getClassForAiPrefillFieldRow(item) {
      if (item.aiSuggestedValue === NONE && !item.currentValue) {
        return "grey--text"
      }
    },
    isAiSuggestedValueNone(item) {
      return item.aiSuggestedValue === NONE && !item.currentValue
    },
    toggleAiPrefillFieldsTableHeaderCheckbox() {
      const selectableItems = this.aiPrefillFieldsTableData
        .filter(item => !this.isAiSuggestedValueNone(item))
      const allSelected     = selectableItems.every(item => this.localSelectedAiSuggestions.includes(item))

      if (allSelected) {
        this.localSelectedAiSuggestions = []
      } else {
        this.localSelectedAiSuggestions = selectableItems.map(item => item)
      }
    },
    areAllIssueFieldsValuesSuggested(result){
      const issueFieldIds                 = result.map(item => item.issueFieldId)
      const allIssueFieldsValuesSuggested = this.issueFieldsList.every(
        item => issueFieldIds.includes(item.issueFieldId
        ))
      return allIssueFieldsValuesSuggested
    }
  },
  watch: {
    issue: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalIssueNotSet          = !this.localIssue
          const isLocalIssueSetButDifferent = !isLocalIssueNotSet &&
            (JSON.stringify(this.localIssue) !== JSON.stringify(newValue))
          if (isLocalIssueNotSet || isLocalIssueSetButDifferent) {
            this.localIssue = {
              ...newValue,
              receivedAt    : newValue.receivedAt ? new Date(newValue.receivedAt) : null,
              acknowledgedAt: newValue.acknowledgedAt ? new Date(newValue.acknowledgedAt) : null,
              assigneeId    : newValue.assigneeId ? newValue.assigneeId : 0,
              dueDate       : newValue.dueDate ? new Date(newValue.dueDate) : null
            }
          }
        }
      }
    },
    issueViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewAssignee          = newValue.select.includes("assigneeId") && this.loggedInUserPolicies["User view"]
          this.canViewDomain            = newValue.select.includes("domainId") && this.loggedInUserPolicies["Domain view"]
          this.canViewAcknowledgedOn    = newValue.select.includes("acknowledgedAt")
          this.canViewLabels            = newValue.select.includes("labels")
          this.canViewReceivedOn        = newValue.select.includes("receivedAt")
          this.canViewStatus            = newValue.select.includes("statusId") && this.loggedInUserPolicies["IssueStatus view"]
          this.canViewCreatedOn         = newValue.select.includes("createdAt")
          this.canViewUpdatedOn         = newValue.select.includes("updatedAt")
          this.canViewClosedOn          = newValue.select.includes("closedAt")
          this.canViewDueDate           = newValue.select.includes("dueDate")
          this.canViewDataRetainedUntil = newValue.select.includes("dataRetainedUntil")
          this.canViewResolution        = newValue.select.includes("resolutionId") && this.loggedInUserPolicies["IssueResolution view"]
        }
      }
    },
    issueUpdatePolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          this.canUpdateSummary        = newValue.set.summary !== undefined
          this.canUpdateDescription    = newValue.set.description !== undefined
          this.canUpdateAssignee       = newValue.set.assigneeId !== undefined
          this.canUpdateDomain         = newValue.set.domainId !== undefined
          this.canUpdateAcknowledgedOn = newValue.set.acknowledgedAt !== undefined
          this.canUpdateLabels         = newValue.set.labels !== undefined
          this.canUpdateReceivedOn     = newValue.set.receivedAt !== undefined
          this.canUpdateStatus         = newValue.set.statusId !== undefined
          this.canUpdateDueDate        = newValue.set.dueDate !== undefined
        } else {
          this.canUpdateSummary        = undefined
          this.canUpdateDescription    = undefined
          this.canUpdateAssignee       = undefined
          this.canUpdateDomain         = undefined
          this.canUpdateAcknowledgedOn = undefined
          this.canUpdateLabels         = undefined
          this.canUpdateReceivedOn     = undefined
          this.canUpdateStatus         = undefined
          this.canUpdateDueDate        = undefined
        }
      }
    },
    isTransitionDone: {
      handler: function(newValue) {
        if (newValue && this.localCurrentTransition) {
          const transitionToStatusId = this.getTransitionToStatusId(this.localCurrentTransition.id)
          if (transitionToStatusId === this.$CONSTANTS.ISSUE_STATUS.CLOSED
            && this.statusesMap[transitionToStatusId].category === ISSUE_STATUS_CATEGORY.DONE) {
            this.notify({
              type      : "success",
              text      : "436",
              parameters: {
                id: this.localIssue.id
              }
            })
          }

          if (this.isScreenDialogOpen) {
            this.isScreenDialogOpen      = false
            this.localCurrentTransition  = undefined
            this.dynamicScreenTitle      = undefined
            this.screenItemsForComponent = []
          }

          if (this.isUpdatingDomainThroughTransition &&
             !this.usersWithAllLevelAccess.includes(this.loggedInUser.id)
          ) {
            this.$router.push({ name: "issues" })
          }
        }
      }
    },
    isCloseButtonDisabled: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.CLOSE_ISSUE.pActions[1].buttonProps.disabled = newValue
      }
    },
    isLabelsInputFocussed: {
      handler: function(newValue) {
        if (!newValue) {
          if (this.isLabelsChanged) {
            this.updateIssue({
              id    : this.localIssue.id,
              labels: this.localIssue.labels
            })
          }
        }
      }
    },
    isUpdatingDomain: {
      handler: function(newValue) {
        this.$DECISIONS.CHANGE_ISSUE_DOMAIN.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.CHANGE_ISSUE_DOMAIN.pActions[0].buttonProps.disabled = newValue
        if (!newValue) {
          this.isChangeDomainDialogOpen = false
        }
      }
    },
    isDomainUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "666",
            parameters: {
              name: this.domainsMap[this.localIssue.domainId].name
            }
          })
          if (!this.usersWithAllLevelAccess.includes(this.loggedInUser.id)) {
            this.$router.push({ name: "issues" })
          }
        }
      }
    },
    aiPrefillFieldStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue === AI_PREFILL_FIELDS_STATUS.DONE || newValue === AI_PREFILL_FIELDS_STATUS.FAILURE) {
          this.clearAiPrefillFieldsPollingInterval()
        } else if (this.showAiPrefillDialog && newValue === AI_PREFILL_FIELDS_STATUS.INITIATED) {
          this.aiPrefillFieldsStatusPolling = setInterval(() => {
            this.loadAiPrefillField({
              id       : this.aiPrefillFieldOfCurrentIssue?.issueId,
              status   : newValue,
              prefillId: this.aiPrefillFieldOfCurrentIssue?.id
            })
          }, AI_PREFILL_FIELDS_POLL_INTERVAL)
        }
      }
    },
    aiPrefillFieldOfCurrentIssue: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue && this.aiPrefillFieldOfCurrentIssue.result) {
          if (!this.showAiPrefillDialog) {
            this.resetAiPrefillField()
            return
          }
          const { result }                    = this.aiPrefillFieldOfCurrentIssue
          const allIssueFieldsValuesSuggested = this.areAllIssueFieldsValuesSuggested(result)
          if (allIssueFieldsValuesSuggested) {
            this.issueFieldsValuesLoaded     = true
            this.noNewAiSuggestionsFound     = result.every(suggestion  => suggestion.value === NO_DATA_FOUND)
            this.noAiSuggestedValueToPrefill = this.noNewAiSuggestionsFound && this.checkIfAllIssueFieldValuesAreEmpty
          }

          this.aiPrefillFieldsTableData = this.issueFieldsList.map(issueField => {
            const aiSuggested    = this.aiPrefillFieldOfCurrentIssue.result.find(aiFieldValue => {
              return aiFieldValue.issueFieldId === issueField.issueFieldId
            })
            const field          = this.fieldsMap.get(issueField.fieldId)
            let aiSuggestedValue = aiSuggested?.value === NO_DATA_FOUND ? NONE : aiSuggested?.value
            let citation         = aiSuggested?.citation
            if (field.type === FIELD_TYPES.SHORT_TEXT.value) {
              const shortTextExceedsLimit = aiSuggestedValue &&
                  aiSuggestedValue.length > MAX_CHARACTER_LIMIT.ISSUE_FIELDS_SHORT_TEXT_FIELD
              if (shortTextExceedsLimit) {
                aiSuggestedValue = NONE
                citation         = ""
              }
            }
            return {
              fieldId     : issueField.fieldId,
              issueFieldId: issueField.issueFieldId,
              label       : issueField.fieldLabel,
              currentValue: this.formattedCurrentValue(issueField),
              aiSuggestedValue,
              citation
            }
          })
        }
      }
    },
    isIssueFieldValueUpdated: {
      handler: function(newValue) {
        if (newValue && this.aiPrefillFieldsSaved) {
          this.notify({
            type: "success",
            text: "1793"
          })
          this.aiPrefillFieldsSaved = false
        }
      }
    },
    isAiPrefillFieldFeedbackSubmitted: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.likeAiPrefillField) {
          this.notify({
            type: "success",
            text: "1822"
          })
          this.likeAiPrefillField = false
        }
      }
    },
    isAiPrefillFieldFeedbackUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1822"
          })
        }
      }
    },
    allowedIssuesAndDomainsForIssueFieldViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.domainIds.includes(this.issue.domainId) || newValue.ids.includes(this.issue.id)) {
            this.canViewIssueFields = true
          }
        }
      }
    }
  }
}